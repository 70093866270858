import styled from 'styled-components';

export const Wrapper = styled.div`
  overflow: hidden;
  position: relative;
`;
export const BG = styled.img`
  opacity: 0.3;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
`;
export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  border: 1px solid white;
  color: white;
  position: relative;
  height: 100vh;
  min-height: -moz-available;
  min-height: -webkit-fill-available;
`;
export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  color: white;
  p {
    align-self: flex-start;
  }
  img {
    margin-top: 1.5rem;
    width: 50%;
    height: auto;
  }
`;
export const ProfileContainer = styled.div`
  color: white;
  display: flex;
  flex: 4;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  h2 {
    font-size: 2rem;
    margin: 0.5rem 0;
  }
  p {
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.2rem;
  }
  p + p {
    margin-top: 0.5rem;
  }
`;
export const LinksContainer = styled.div`
  flex: 4;
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 1.2rem;
  a,
  a:link,
  a:visited,
  a:link:active,
  a:visited:active {
    color: rgba(255, 255, 255, 0.8);
    text-decoration: underline;
    z-index: 10;
    align-items: center;
    display: flex;
  }
  a:hover {
    color: rgba(255, 255, 255, 1);
  }
  a + a {
    margin-top: 1.0rem;
  }
  a > svg {
    margin-right: 0.5rem;
  } */
`;

import axios from 'axios';

export async function getCandidate(id) {
  let token = await getToken();

  try {
    const response = await axios.get(
      `https://gobrasa.io/api/conf/hacks2022/${id}`,
      {
        headers: {
          'x-access-token': token
        },
      },
    );
    return response;
  }catch(error) {

  }
}


async function getToken(){
  const response = await axios.post("https://gobrasa.io/api/auth/apiClient/login", {
    id:"6225272cbb356d00169aa6ea",
    secret: "03a55213608e893cc7f4b4733e43284cb0ceed76be3587acdcc8bc2f079321a02fd9e84ad43d3de722bc2f72869d6a71e12cff8c1482fd9b21e59ac87ac6cd0427553457e443a5889b8a3b4655b8daec88ec93bd14e10145238059b19b534676bd401489b654ebe6a1bdbdc36782685674f37d8613c9307af66581dde4ff6709584793ad5c4b5d506af8ba24aeab675fce039e46d3985c1eda2019363857f2b4d2b07898d5dd2d2bec4ee34b792c542549fb72309b9654b333502d79f914f19895bb659ded740049a797f2e9e1c8758023bbc39e31952620ab835fbdc9d2eb5bcb70241d0535e82c99e3d271d2b074d881d75494441525261b5a8300d456f5ae",
  }
  );
  if(!response){
    return false;
  }
  return response.data.token;
}

async function refreshToken(){
  const token = await getToken();
  if(!token){
    return false;
  }
  localStorage.setItem("@hacks:nfc_token", token);
  return token;
}

import React, { useEffect, useState } from 'react';
import { Typewriter, useTypewriter } from 'react-simple-typewriter';
import {
  RiGithubLine,
  RiLinkedinLine,
  RiLinksLine,
  RiMailLine,
  RiWhatsappLine,
} from 'react-icons/ri';
import { useParams } from 'react-router-dom'; // use url params
import RandomTextReveal from '../../components/RandomTextReveal';
import {
  BG,
  Container,
  Header,
  LinksContainer,
  ProfileContainer,
  Wrapper,
} from './styles';
import { getCandidate } from '../../services/brasa';

function Candidate() {
  const { id } = useParams();
  const [candidate, setCandidate] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const { data } = await getCandidate(id);
        const {
          uni_exterior_nome: uni,
          pos_exterior_nome: pos,
          uni_curso: uniCurso,
          pos_area: posCurso,
        } = data.user;

        let university = uni;

        if (pos) {
          university = pos;
        }

        let curso = uniCurso;

        if (posCurso) {
          curso = posCurso;
        }
        const mountedCandidate = {
          ...data,
          university,
          name: `${data.user.hiv_first_name} ${data.user.hiv_last_name}`,
          curso,
        };
        console.log('mountedCandidate', mountedCandidate);
        setCandidate(mountedCandidate);
      } catch (err) {
        setCandidate(null);
      }
      setLoading(false);
    };

    fetch();
  }, [id]);

  const { text: loadingText } = useTypewriter({
    words: ['Loading...'],
    loop: true,
  });

  return (
    <Wrapper>
      <BG src="./assets/CristoW.png" />
      <Container>
        {loading || !candidate ? (
          <p>{loadingText}</p>
        ) : (
          <>
            <Header>
              <p>BrasaHacksOS-2.0.22$</p>
              <img src="./assets/LogoWHacks.png" alt="logo" />
            </Header>
            <ProfileContainer>
              <h2>
                <Typewriter words={[candidate.name]} typeSpeed={60} />
              </h2>
              <p>
                {candidate.university && (
                  <Typewriter words={[candidate.university]} typeSpeed={60} />
                )}
              </p>
              <p>
                {candidate.curso && (
                  <Typewriter words={[candidate.curso]} typeSpeed={60} />
                )}
              </p>
            </ProfileContainer>
            <LinksContainer>
              {candidate.user.hiv_mobile_personal && (
                <a href={`https://wa.me/${candidate.user.hiv_mobile_personal}`}>
                  <RiWhatsappLine color="#FFF" size={32} />
                  <RandomTextReveal text="WhatsApp" />
                </a>
              )}
              {candidate.user.hiv_linkedin && (
                <a href={candidate.user.hiv_linkedin}>
                  <RiLinkedinLine color="#FFF" size={32} />
                  <RandomTextReveal text="LinkedIn" />
                </a>
              )}
              <a href={`malito:${candidate.user.hiv_email}`}>
                <RiMailLine color="#FFF" size={32} />
                <RandomTextReveal text="Email" />
              </a>
              {candidate.github && (
                <a href={candidate.github}>
                  <RiGithubLine color="#FFF" size={32} />
                  <RandomTextReveal text="GitHub" />
                </a>
              )}
              {candidate.personal_port && (
                <a href={candidate.personal_port}>
                  <RiLinksLine color="#FFF" size={32} />
                  <RandomTextReveal text="Personal Website" />
                </a>
              )}
            </LinksContainer>
          </>
        )}
      </Container>
    </Wrapper>
  );
}

export default Candidate;

import React from 'react';
import { RandomReveal } from 'react-random-reveal';

// import { Container } from './styles';

function RandomTextReveal({ text }) {
  return (
    <RandomReveal
      isPlaying
      duration={3}
      characters={text}
      characterSet={[0, 1]}
    />
  );
}

export default RandomTextReveal;
